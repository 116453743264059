// The Main Site Object
window.Site = {

    services   : document.querySelectorAll('.service-title'),
    bioBtn     : document.querySelector('.bio-button'),
    bioContent : document.querySelectorAll('.bio-column'),

    // Init Function
    liftoff : () => {
        Site.displayBioOnClick()
        Site.displayServicesOnClick()
    },

    displayBioOnClick : () => {
        Site.bioBtn.addEventListener('click' , () => {

            Site.bioBtn.innerHTML = (Site.bioBtn.value == "+") ? "Chiudi" : "Scopri di più";
            Site.bioBtn.value     = (Site.bioBtn.value == "+") ? "-" : "+";

            Site.bioContent.forEach(section => {
                section.classList.toggle('visible')
            })

        })
    },

    displayServicesOnClick : () => {
        Site.services.forEach(service => {
            service.addEventListener('click' , () => {
                service.value = (service.value == "+") ? "-" : "+";
                service.nextElementSibling.classList.toggle('visible')
            })
        })
    },


}

// Launch The Script
Site.liftoff()